.text-with-action-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem; // 32px
  padding-inline: 8.75rem; // 140px
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .text-with-action-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    color: #1d1d1f;
    text-align: center;
    text-transform: uppercase;
  }

  .text-with-action-description {
    max-width: 42rem; // 672px
    font-size: 1.25rem; // 20px
    letter-spacing: 0.2px;
    color: #1d1d1f;
    text-align: center;
    text-wrap: pretty;
  }
}

@media (max-width: 960px) {
  .text-with-action-section {
    // padding-top: 1.563rem; // 25px
    // padding-bottom: 1.563rem; // 25px
    gap: 1rem; // 16px
    padding-inline: 5%;

    .text-with-action-title {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .text-with-action-description {
      font-size: 1.125rem; // 18px
      letter-spacing: 0.18px;
    }
  }
}
