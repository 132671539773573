.latest-news-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8.75rem 0 8.75rem;

  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  &.latest-news-section-news {
    padding-top: 11.063rem; // 137px + 40px
    padding-bottom: 4.813rem; // 77px
  }

  .latest-news-label {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #1d1d1f;
    margin-bottom: 3.438rem; // 55px
  }

  .latest-news-container {
    .latest-news-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .latest-news-content-container {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 100%;

        .latest-news-content-container-wrapper {
          display: flex;
          width: 53%;
          flex-shrink: 0;
          order: 0;

          .latest-news-content-container-left-scroll,
          .latest-news-content-container-right-scroll {
            display: none;
          }

          .latest-news-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-inline: 5%;
            padding-top: 2%;
            padding-bottom: 2%;
            width: 100%;

            &.bg-beige {
              background-color: #f4f0e6;
            }

            &.bg-grey {
              background-color: #202122;
            }

            .latest-news-content-container-wrapper-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;

              .latest-news-content-upper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .latest-news-content-title {
                  font-size: 1.938rem; // 31px
                  letter-spacing: 4.65px;
                  text-transform: uppercase;
                  text-align: center;
                  color: #1d1d1f;

                  &.light {
                    color: #f7f8f9;
                  }
                }

                .latest-news-content-subtitle {
                  font-size: 1.063rem; // 17px
                  letter-spacing: 1.9px;
                  font-family: Campton-Bold;
                  text-transform: uppercase;
                  text-align: center;
                  color: #c1b596;

                  &.light {
                    color: #c4b897;
                  }
                }
              }

              .latest-news-content-description {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 0.875rem; // 14px
                font-size: 1.125rem; // 18px
                letter-spacing: 0.18px;
                line-height: 1.4;
                text-align: center;
                color: #1d1d1f;
                text-wrap: pretty;

                margin-bottom: 1.25rem; // 20px

                &.light {
                  color: #f7f8f9;
                }
              }

              .latest-news-content-download {
                font-size: 1.125rem; // 18px
                letter-spacing: 1.9px;
                font-family: Campton-Bold;
                text-transform: uppercase;
                color: #1d1d1f;
                width: fit-content;
                padding: 1% 4%;
                background-color: transparent;
                border: 1px solid #1d1d1f;

                &.light {
                  color: #f7f8f9;
                  border: 1px solid #f7f8f9;
                }
              }
            }
          }
        }

        .order-first {
          order: -1;
        }

        .order-last {
          order: 1;
        }

        .latest-news-content-image-container {
          width: 47%;

          .latest-news-content-image {
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .latest-news-section {
    .latest-news-container {
      .latest-news-wrapper {
        .latest-news-content-container {
          .latest-news-content-container-wrapper {
            .latest-news-content {
              padding-inline: 2%;
            }

            .latest-news-content-container-wrapper-content {
              .latest-news-content-upper {
                .latest-news-content-title {
                  font-size: 1.25rem; // 20px
                  letter-spacing: 2.4px;
                }

                .latest-news-content-subtitle {
                  font-size: 0.875rem; // 14px
                  letter-spacing: 1.34px;
                }
              }

              .latest-news-content-description {
                font-size: 0.875rem; // 14px
                letter-spacing: 0.12px;
                margin-bottom: 0.625rem; // 10px
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .latest-news-section {
    margin: 0;
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px

    &.latest-news-section-news {
      padding-top: 7.5rem; // 80px + 25px
      padding-bottom: 2.5rem; // 40px
    }

    .latest-news-label {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
      margin-bottom: 1.375rem; // 22px
    }

    .latest-news-container {
      width: 100%;

      .latest-news-wrapper {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        cursor: grab;

        &::-webkit-scrollbar {
          display: none;
        }

        &:active {
          cursor: grabbing;
        }

        flex-direction: initial;
        transition: transform 0.3s ease;

        .latest-news-content-container {
          flex-direction: column;
          flex: 0 0 100%;
          scroll-snap-align: start;

          .latest-news-content-container-wrapper {
            border-bottom: 0.5px solid #1d1d1f;
            order: 1;
            width: 100%;

            .latest-news-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5% 2%;
              gap: 0.625rem; // 10px
              user-select: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;

              height: 400px;

              .latest-news-content-container-left-scroll,
              .latest-news-content-container-right-scroll {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.75rem; // 44px
                flex-shrink: 0;
                -webkit-tap-highlight-color: transparent;
              }

              .latest-news-content-container-wrapper-content {
                .latest-news-content-description {
                  font-size: 1.125rem; // 18px
                  letter-spacing: 0.18px;
                  margin-bottom: 1rem; // 16px
                }

                .latest-news-content-download {
                  font-size: 1rem; // 16px
                  letter-spacing: 1.6px;
                }
              }
            }
          }

          .latest-news-content-image-container {
            width: 100%;
            order: 0;
            border-top: 0.5px solid #1d1d1f;
          }
        }
      }
    }

    .latest-news-bullets {
      margin-top: 1.375rem; // 22px
      display: flex;
      justify-content: center;
      gap: 0.5rem; // 8px

      .latest-news-bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(29, 29, 31, 0.17);
        cursor: pointer;

        &.active {
          background-color: #1d1d1f;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .latest-news-section {
    .latest-news-container {
      .latest-news-wrapper {
        .latest-news-content-container {
          .latest-news-content-container-wrapper {
            .latest-news-content {
              height: 440px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .latest-news-section {
    .latest-news-container {
      .latest-news-wrapper {
        .latest-news-content-container {
          .latest-news-content-container-wrapper {
            .latest-news-content {
              height: 460px;
            }
          }
        }
      }
    }
  }
}
