.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.438rem; // 55px

  padding-top: 14.188rem; // 137px + 40px + 40px
  padding-inline: 8.75rem; // 140px
  padding-bottom: 2.5rem; // 40px

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem; // 30px

    .hero-title {
      font-size: 2.5rem; // 40px
      letter-spacing: 6px;
      color: #1d1d1f;
      text-align: center;
      text-transform: uppercase;
    }

    .hero-description {
      max-width: 62.188rem; // 995px
      text-align: center;
      text-wrap: pretty;
      font-size: 1.375rem; // 22px
      letter-spacing: 0.2px;
      color: #1d1d1f;
    }
  }

  .hero-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.hero-section {
  @media (max-width: 960px) {
    gap: 1.5rem; // 24px
    padding-inline: 0;
    padding-top: 6.563rem; // 105px
    padding-bottom: 0;

    .hero-content {
      gap: 0.875rem; // 14px
      padding-inline: 10%;

      .hero-title {
        font-size: 1.438rem; // 23px
        letter-spacing: 3.45px;
      }

      .hero-description {
        font-size: 1.125rem; // 18px
        letter-spacing: 0.18px;
      }
    }

    .hero-image {
      aspect-ratio: 9/13;
      max-height: 80svh;
      padding-inline: 2.75rem; // 44px
      border-top: 1px solid #1d1d1f;
      border-bottom: 1px solid #1d1d1f;
    }
  }
}
