.text-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 8.75rem;
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .text-section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem; // 12px

    .text-section-title {
      font-size: 2.188rem; // 35px
      font-family: Campton-Medium;
      letter-spacing: 3.8px;
      text-transform: uppercase;
      text-align: center;
      color: #1d1d1f;
    }

    .text-section-subtitle {
      font-size: 2.938rem; // 47px
      font-family: Campton-ExtraLight;
      letter-spacing: 1.18px;
      text-transform: uppercase;
      text-align: center;
      color: #1d1d1f;
    }

    .text-section-description {
      font-size: 1.188rem; // 19px
      letter-spacing: 0.2px;
      color: #1d1d1f;
      text-align: center;
      text-wrap: pretty;

      margin-top: 1.25rem; // 20px
      max-width: 57.5rem; // 926px
    }

    .text-section-button {
      margin-top: 1.25rem; // 20px
    }
  }
}

@media (max-width: 960px) {
  .text-section {
    position: relative;
    padding-top: 3.125rem; // 50px
    padding-bottom: 1.563rem; // 25px
    padding-inline: 5%;

    .text-section-content {
      gap: 0;

      .text-section-title {
        position: absolute;
        top: -4.375rem; // -70px
        font-size: 1.125rem; // 18px
        letter-spacing: 1.95px;
        font-family: Campton;
        color: #f7f8f9;
        width: 100%;
        padding-inline: 5%;
      }

      .text-section-subtitle {
        position: absolute;
        top: -3.125rem; // -50px
        font-size: 1.438rem; // 23px
        letter-spacing: 2.3px;
        font-family: Campton;
        color: #f7f8f9;
        width: 100%;
        padding-inline: 5%;
      }

      &:has(.text-section-button) {
        .text-section-subtitle {
          top: -8rem; // -80px
        }
      }

      .text-section-description {
        margin-top: 0;
        font-size: 1.125rem; // 18px
        letter-spacing: 0.18px;
      }

      .text-section-button {
        position: absolute;
        top: -4.75rem; // -76px
      }
    }
  }
}
