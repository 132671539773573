.our-story-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.438rem; // 55px
  max-width: 100rem; // 1600px
  margin: 0 auto;

  padding-inline: 8.75rem; // 140px
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  @media (max-width: 960px) {
    padding-inline: 5%;
    gap: 1.5rem; // 24px
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px
  }

  .our-story-label {
    text-align: center;
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-transform: uppercase;
    color: #1d1d1f;

    @media (max-width: 960px) {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }
  }

  .our-story-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1.5rem; // 24px
  }

  .our-story-description-content {
    font-size: 1.375rem; // 22px
    letter-spacing: 0.2px;
    color: #1d1d1f;
    text-wrap: pretty;

    @media (max-width: 960px) {
      font-size: 1.125rem; // 18px
      letter-spacing: 0.18px;
    }
  }

  .our-story-see-more {
    display: block;
    font-size: 1.188rem; // 19px
    letter-spacing: 1.78px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #1d1d1f;

    &.used {
      display: none;
    }
  }
}
