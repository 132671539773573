.item-table-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15% 10%;

  border-bottom: 1px solid #1d1d1f;
  border-right: 1px solid #1d1d1f;

  &:nth-child(3n) {
    border-right: none;
  }

  &:last-child,
  &:nth-last-child(-n + 3) {
    border-bottom: none;
  }

  .item-table-measure {
    font-size: 4.063rem; // 65px
    font-family: SangBleu-Light;
    text-transform: uppercase;
    color: #c4b897;
  }

  .item-table-content {
    text-align: center;
    text-wrap: pretty;
    color: #1d1d1f;
    font-size: 1.25rem; // 20px
    letter-spacing: 0.2px;
  }
}

@media (max-width: 960px) {
  .item-table-component {
    padding: 5% 10%;
    border-left: 1px solid #1d1d1f;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .item-table-measure {
      font-size: 2.5rem; // 40px
    }

    .item-table-content {
      font-size: 1.125rem; // 18px
      letter-spacing: 0.18px;
    }
  }
}
