.podcast-activity-section {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 8.75rem 0 8.75rem;
  padding-top: 5rem; // 80px
  padding-bottom: 7.5rem; // 120px
  gap: 10%;

  .podcast-activity-title {
    position: absolute;
    top: 1.875rem; // 30px
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    text-wrap: balance;
    font-size: 3.188rem; // 51px
    letter-spacing: 5.1px;
    text-transform: uppercase;
    color: #1d1d1f;
    width: 100%;
  }

  .podcast-activity-content-container {
    margin-top: 2.5rem; // 40px
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem; // 40px
    max-width: 43.75rem; // 700px
    width: 100%;

    .podcast-activity-content {
      font-size: 1.25rem; // 20px
      letter-spacing: 0.2px;
      text-align: center;
      color: #1d1d1f;
      text-wrap: pretty;
    }
  }
}

@media (max-width: 960px) {
  .podcast-activity-section {
    flex-direction: column;
    margin: 0;
    padding-inline: 5%;
    padding-top: 2.5rem; // 40px
    padding-bottom: 3.75rem; // 60px

    .podcast-activity-title {
      top: 0;
      font-size: 1.875rem; // 30px
      letter-spacing: 3px;
    }

    .podcast-activity-content-container {
      gap: 1.5rem; // 24px

      .podcast-activity-content {
        font-size: 1.125rem; // 18px
        letter-spacing: 0.18px;
      }
    }
  }
}
