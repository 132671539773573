.video-section {
  position: relative;
  overflow: hidden;
  padding-top: 6.25rem; // 100px
  padding-bottom: 2.5rem; // 40px

  @media (max-width: 960px) {
    padding-top: 5rem; // 80px
    padding-bottom: 1.563rem; // 25px
  }

  .video-section-video-container {
    position: relative;

    .video-section-video {
      height: calc(100svh - 81.24px);
      object-fit: cover;

      @media (max-width: 960px) {
        height: calc(100svh - 65px);
      }
    }

    .video-background-gradient {
      @media (max-width: 960px) {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(to top, #1d1d1f, #1d1d1f00);
      }
    }
  }

  &:hover {
    .video-text-left {
      visibility: visible;
      left: 10%;
      opacity: 1;
      transition: left 1s cubic-bezier(0.23, 0.62, 0.4, 0.86), opacity 1s ease;
    }

    .video-text-right {
      visibility: visible;
      right: 10%;
      opacity: 1;
      transition: right 1s cubic-bezier(0.23, 0.62, 0.4, 0.86), opacity 1s ease;
    }
  }

  &:not(:hover) {
    .video-text-left {
      visibility: visible;
      left: 0;
      opacity: 0;
      transition: left 1s cubic-bezier(0.23, 0.62, 0.4, 0.86), opacity 1s ease;
    }

    .video-text-right {
      visibility: visible;
      right: 0;
      opacity: 0;
      transition: right 1s cubic-bezier(0.23, 0.62, 0.4, 0.86), opacity 1s ease;
    }
  }

  .video-text-left {
    position: absolute;
    bottom: 15%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, left 0.3s ease;

    @media (max-width: 960px) {
      bottom: 14%;
      left: 50% !important;
      transform: translateX(-50%);
      text-align: center;
      width: 100%;
      padding-inline: 5%;
    }
  }

  .video-text-right {
    position: absolute;
    bottom: 15%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, right 0.3s ease;

    @media (max-width: 960px) {
      font-family: Campton-ExtraLight;
      bottom: 10%;
      right: 50% !important;
      transform: translateX(50%);
      text-align: center;
      width: 100%;
      padding-inline: 5%;
    }
  }

  .video-text {
    font-size: 1.188rem; // 19px
    letter-spacing: 2.25px;
    text-transform: uppercase;
    color: #f6f7f8;

    @media (max-width: 960px) {
      font-size: 1.063rem; // 17px
      letter-spacing: 2.4px;
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  .sound-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 15%;
    right: 5%;

    @media (max-width: 960px) {
      width: 1.938rem; // 31px
      height: 1.938rem; // 31px
      bottom: 4%;
    }
  }

  .video-button {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 960px) {
      bottom: 4%;
    }
  }

  .video-section-carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .video-section-carousel-item {
      display: flex;
      transition: transform 0.5s ease;

      .video-section-carousel-item-image {
        width: 100%;
        height: 100svh;
        object-fit: cover;
      }
    }

    .carousel-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.5);
      color: white;
      padding: 10px;
      border: none;
      cursor: pointer;
      z-index: 10;

      &.left {
        left: 10px;
      }

      &.right {
        right: 10px;
      }
    }
  }
}
