.button-left-icon {
  width: 2.938rem; // 47px
  height: 2.938rem; // 47px
  rotate: 90deg;

  @media (max-width: 960px) {
    width: 1.938rem; // 31px
    height: 1.938rem; // 31px
  }
}
