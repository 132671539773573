.our-talent-activity-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 8.75rem; // 140px
  padding-top: 5rem; // 80px
  padding-bottom: 5rem; // 80px
  gap: 3.75rem; // 60px

  .our-talent-activity-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-transform: uppercase;
    text-align: center;
    color: #1d1d1f;
  }

  .our-talent-activity-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.875rem; // 30px

    .our-talent-activity-item-media {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
    }
  }

  .our-talent-activity-button {
    font-size: 1.125rem; // 18px
    letter-spacing: 2.7px;
    text-align: center;
    color: #bcb194;
    text-decoration: underline;
    text-transform: uppercase;
  }
}

@media (max-width: 960px) {
  .our-talent-activity-section {
    margin: 0;
    padding-inline: 5%;
    padding-top: 2.5rem; // 40px
    padding-bottom: 2.5rem; // 40px
    gap: 1.875rem; // 30px

    .our-talent-activity-title {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .our-talent-activity-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem; // 20px
    }
  }
}
