.mozaic-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8.75rem 0 8.75rem;
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .mozaic-container {
    .mozaic-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .mozaic-content-container {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 100%;

        .mozaic-content-image-container {
          width: 47%;

          .mozaic-content-image {
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
          }

          .sound-container {
            position: absolute;
            bottom: 4%;
            right: 2%;
          }

          &.order-first {
            .sound-container {
              right: auto;
              left: 2%;
            }
          }
        }

        .mozaic-content-container-wrapper {
          display: flex;
          width: 53%;
          flex-shrink: 0;
          order: 0;

          .mozaic-content-container-left-scroll,
          .mozaic-content-container-right-scroll {
            display: none;
          }

          .mozaic-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-inline: 5%;
            padding-top: 2%;
            padding-bottom: 2%;
            width: 100%;

            &.bg-beige {
              background-color: #f4f0e6;
            }

            &.bg-grey {
              background-color: #202122;
            }

            .mozaic-content-container-wrapper-content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 0.875rem; // 14px
              width: 100%;

              .mozaic-content-upper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .mozaic-content-title {
                  font-size: 1.938rem; // 31px
                  letter-spacing: 4.65px;
                  text-transform: uppercase;
                  text-align: center;
                  color: #1d1d1f;

                  &.light {
                    color: #f7f8f9;
                  }
                }

                .mozaic-content-subtitle {
                  font-size: 1.063rem; // 17px
                  letter-spacing: 1.9px;
                  font-family: Campton-Bold;
                  text-transform: uppercase;
                  text-align: center;
                  color: #c1b596;

                  &.light {
                    color: #c4b897;
                  }
                }
              }

              .mozaic-content-description {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 1.125rem; // 18px
                letter-spacing: 0.18px;
                line-height: 1.4;
                text-align: center;
                color: #1d1d1f;
                text-wrap: pretty;

                &.light {
                  color: #f7f8f9;
                }
              }

              .mozaic-content-download {
                font-size: 1.125rem; // 18px
                letter-spacing: 1.9px;
                font-family: Campton-Bold;
                text-transform: uppercase;
                color: #1d1d1f;
                width: fit-content;
                padding: 1% 4%;
                background-color: transparent;
                border: 1px solid #1d1d1f;

                &.light {
                  color: #f7f8f9;
                  border: 1px solid #f7f8f9;
                }
              }

              .mozaic-content-companies-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 0.625rem; // 10px

                .mozaic-content-companies-container-logo {
                  max-height: 100px;
                  width: auto;
                  object-fit: contain;
                  margin: 0 auto;
                }
              }
            }
          }
        }

        .order-first {
          order: -1;
        }

        .order-last {
          order: 1;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .mozaic-section {
    .mozaic-container {
      .mozaic-wrapper {
        .mozaic-content-container {
          .mozaic-content-container-wrapper {
            .mozaic-content {
              padding-inline: 2%;

              .mozaic-content-container-wrapper-content {
                .mozaic-content-title {
                  font-size: 1.25rem; // 20px
                  letter-spacing: 2.4px;
                }

                .mozaic-content-subtitle {
                  font-size: 0.875rem; // 14px
                  letter-spacing: 1.34px;
                }

                .mozaic-content-description {
                  font-size: 0.875rem; // 14px
                  letter-spacing: 0.12px;
                }

                .mozaic-content-companies-container {
                  .mozaic-content-companies-container-logo {
                    max-height: 60px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .mozaic-section {
    margin: 0;
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px

    .mozaic-container {
      width: 100%;

      .mozaic-wrapper {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        cursor: grab;

        &::-webkit-scrollbar {
          display: none;
        }

        &:active {
          cursor: grabbing;
        }

        flex-direction: initial;
        transition: transform 0.3s ease;

        .mozaic-content-container {
          flex-direction: column;
          flex: 0 0 100%;
          user-select: none;
          scroll-snap-align: start;

          .mozaic-content-container-wrapper {
            border-bottom: 0.5px solid #1d1d1f;
            order: 1;
            width: 100%;
            height: auto;

            .mozaic-content {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5% 2%;
              gap: 0.625rem; // 10px
              user-select: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;

              height: 400px;

              .mozaic-content-container-left-scroll,
              .mozaic-content-container-right-scroll {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.75rem;
                flex-shrink: 0;
                -webkit-tap-highlight-color: transparent;
              }

              .mozaic-content-container-wrapper-content {
                justify-content: flex-start;

                .mozaic-content-description {
                  font-size: 1.125rem; // 18px
                }

                .mozaic-content-download {
                  font-size: 1rem; // 16px
                  letter-spacing: 1.6px;
                }

                .mozaic-content-companies-container {
                  gap: 1rem; // 16px

                  .mozaic-content-companies-container-logo {
                    max-height: 80px;
                  }
                }
              }
            }
          }

          .mozaic-content-image-container {
            position: relative;
            width: 100%;
            order: 0;
            border-top: 0.5px solid #1d1d1f;

            &.order-first {
              .sound-container {
                left: auto;
                right: 2%;
              }
            }
          }
        }
      }
    }

    .mozaic-bullets {
      margin-top: 1.375rem; // 22px
      display: flex;
      justify-content: center;
      gap: 0.5rem; // 8px

      .mozaic-bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(29, 29, 31, 0.17);
        cursor: pointer;

        &.active {
          background-color: #1d1d1f;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .mozaic-section {
    .mozaic-container {
      .mozaic-wrapper {
        .mozaic-content-container {
          .mozaic-content-container-wrapper {
            .mozaic-content {
              height: 440px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .mozaic-section {
    .mozaic-container {
      .mozaic-wrapper {
        .mozaic-content-container {
          .mozaic-content-container-wrapper {
            .mozaic-content {
              height: 460px;
            }
          }
        }
      }
    }
  }
}
