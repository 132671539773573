.why-join-us-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.438rem; // 55px

  padding-inline: 8.75rem;
  padding-top: 6.25rem; // 100px
  padding-bottom: 2.5rem; // 40px

  .why-join-us-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem; // 30px

    .why-join-us-title {
      font-size: 2.5rem; // 40px
      letter-spacing: 6px;
      color: #1d1d1f;
      text-align: center;
      text-transform: uppercase;
    }

    .why-join-us-description {
      max-width: 62.188rem; // 995px
      text-align: center;
      text-wrap: pretty;
      font-size: 1.25rem; // 20px
      letter-spacing: 0.2px;
      color: #1d1d1f;
    }
  }

  .why-join-us-image {
    width: 100%;

    object-fit: cover;
    aspect-ratio: 9/4;
  }
}

.why-join-us-section {
  @media (max-width: 960px) {
    padding-inline: 0;
    padding-top: 0;
    // padding-bottom: 1.563rem; // 25px
    gap: 2.5rem; // 40px
    border-bottom: 1px solid #1d1d1f;

    .why-join-us-content {
      padding-inline: 5%;
      position: relative;

      .why-join-us-title {
        font-size: 1.438rem;
        letter-spacing: 2.3px;
        color: #f7f8f9;
        position: absolute;
        top: -85px;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        padding-inline: 5%;
      }

      .why-join-us-description {
        font-size: 1.125rem; // 18px
        letter-spacing: 0.18px;
      }
    }

    .why-join-us-image {
      aspect-ratio: 9/15;
      max-height: 80svh;
    }
  }
}
