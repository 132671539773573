.out-of-talent-activity-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 8.75rem; // 140px
  padding-top: 7.5rem; // 120px
  padding-bottom: 5rem; // 80px
  gap: 2.5rem; // 40px

  .out-of-talent-activity-media-container {
    width: 194px;
    height: 80px;

    .out-of-talent-activity-media {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .out-of-talent-activity-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-transform: uppercase;
    text-align: center;
    color: #1d1d1f;
  }

  .out-of-talent-activity-content {
    font-size: 1.25rem; // 20px
    letter-spacing: 0.2px;
    text-align: center;
    color: #1d1d1f;
  }
}

@media (max-width: 960px) {
  .out-of-talent-activity-section {
    margin: 0;
    padding-inline: 5%;
    padding-top: 3.75rem; // 60px
    padding-bottom: 2.5rem; // 40px
    gap: 1.25rem; // 20px

    .out-of-talent-activity-title {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .out-of-talent-activity-content {
      font-size: 1.125rem; // 18px
      letter-spacing: 0.18px;
    }
  }
}
