@import "../../utils/scss/all";

.page-title {
  margin-top: 13vh;
  padding: 0 7vw 4vh;

  .animatedLine {
    background-color: $black;
    height: 6px;
    width: 0px;
  }

  h1 {
    font-family: "Cantarell", sans-serif;
    font-size: 2.5rem;
    color: $black;
    letter-spacing: 4px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 25px;
  }
}

.text-contentSectionManager {
  font-size: 1rem;
  color: $black;
  padding: 0 7vw 4vh;
}

@media screen and (max-width: 728px) {
  .page-title {
    margin-top: 12vh;

    .animatedLine {
      height: 4px;
    }

    h1 {
      letter-spacing: 3px;
      margin-top: 15px;
    }
  }
}
