.distribution-news-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8.75rem 0 8.75rem;
  padding-top: 5rem; // 80px
  padding-bottom: 5rem; // 80px

  .distribution-news-container {
    .distribution-news-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5rem; // 80px

      .distribution-news-news-divider {
        display: none;
      }

      .distribution-news-news-container {
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 5%;

        .distribution-news-news-container-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 50%;

          .distribution-news-news-border {
            position: absolute;
            width: 17px;
            height: 17px;

            &-top-left {
              top: 0;
              left: 0;
              border-top: 1px solid #707070;
              border-left: 1px solid #707070;
            }

            &-top-right {
              top: 0;
              right: 0;
              border-top: 1px solid #707070;
              border-right: 1px solid #707070;
            }

            &-bottom-left {
              bottom: 0;
              left: 0;
              border-bottom: 1px solid #707070;
              border-left: 1px solid #707070;
            }

            &-bottom-right {
              bottom: 0;
              right: 0;
              border-bottom: 1px solid #707070;
              border-right: 1px solid #707070;
            }
          }

          .distribution-news-news-companies-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.625rem; // 10px
            overflow: hidden;

            .distribution-news-news-companies-container-logo {
              flex: 1;
              object-fit: contain;
              margin: 0 auto;
              max-height: 60px;
            }
          }

          .distribution-news-news-description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 1.125rem; // 18px
            letter-spacing: 0.18px;
            text-align: center;
            color: #1d1d1f;
            text-wrap: pretty;
          }

          .distribution-news-news-link-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;

            .distribution-news-news-link {
              font-size: 1.125rem; // 18px
              letter-spacing: 2.7px;
              text-align: center;
              color: #bcb194;
              text-decoration: underline;
              text-transform: uppercase;
            }
          }
        }

        .distribution-news-news-image-container {
          width: 50%;

          .distribution-news-news-image {
            width: 100%;
            height: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
          }

          .sound-container {
            position: absolute;
            bottom: 4%;
            right: 2%;
          }
        }

        .distribution-news-news-companies-container {
          display: none;
        }

        .order-first {
          order: -1;
        }

        .order-last {
          order: 1;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .distribution-news-section {
    .distribution-news-container {
      .distribution-news-wrapper {
        .distribution-news-news-container {
          .distribution-news-news-container-wrapper {
            .distribution-news-news-description {
              font-size: 0.875rem; // 14px
              letter-spacing: 0.12px;
            }

            .distribution-news-news-link-container {
              .distribution-news-news-link {
                font-size: 0.875rem; // 14px
                letter-spacing: 1.34px;
              }
            }
          }

          .distribution-news-news-companies-container {
            .distribution-news-news-companies-container-logo {
              max-height: 40px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .distribution-news-section {
    margin: 0;
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px

    .distribution-news-container {
      .distribution-news-wrapper {
        gap: 0;

        .distribution-news-news-divider {
          display: block;
          width: 100%;
          height: 1px;
          background-color: #1d1d1f;
        }

        .distribution-news-news-container {
          margin-top: 2.188rem; // 35px
          margin-bottom: 2.188rem; // 35px
          flex-direction: column;
          gap: 1.563rem; // 25px

          .distribution-news-news-companies-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.625rem; // 10px
            overflow: hidden;
            padding-inline: 5%;

            .distribution-news-news-companies-container-logo {
              flex: 1;
              object-fit: contain;
              margin: 0 auto;
              max-height: 70px;
            }
          }

          .distribution-news-news-container-wrapper {
            width: 100%;
            gap: 1.563rem; // 25px
            padding-inline: 5%;

            .distribution-news-news-border {
              display: none;
            }

            .distribution-news-news-companies-container {
              display: none;
            }

            .distribution-news-news-description {
              font-size: 1.125rem; // 18px
            }

            .distribution-news-news-link-container {
              .distribution-news-news-link {
                font-size: 1.125rem; // 18px
                letter-spacing: 2.1px;
              }
            }
          }

          .distribution-news-news-image-container {
            width: 100%;
          }
        }
      }
    }
  }
}
