.header-component {
  width: 100%;
  height: 6.25rem; // 100px

  background-color: rgba(245, 245, 247, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  z-index: 20;
  position: fixed;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #1c1c1e;

  @media (max-width: 960px) {
    display: none;
  }

  .header-logo {
    padding-inline: 2.125rem; // 34px
    width: 8.75rem; // 140px

    .header-logo-symbol {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  .header-border {
    border-left: 1px solid #1c1c1e;
    height: 100%;
  }

  .header-menu {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .header-menu-left,
    .header-menu-right {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      gap: 2rem; // Ajustez cet espace selon vos besoins
    }

    .header-menu-logo-wrapper {
      padding: 0 2rem;
      margin-bottom: 0.625rem;

      .header-menu-logo {
        width: 11.25rem; // 180px
        height: auto;
      }
    }

    .header-menu-element {
      cursor: pointer;
      font-size: 1.563rem; // 25px
      color: #1d1d1f;

      &:hover {
        color: #bcb194;
      }
    }
  }

  .header-lang {
    display: flex;
    gap: 1rem;
    width: 8.75rem; // 140px
    height: 1.25rem;
    padding-inline: 2rem;

    .header-lang-link {
      font-size: 1.25rem; // 20px
      line-height: 1.25rem;
      text-transform: uppercase;
      color: #1d1d1f;
      opacity: 0.48;
    }

    .header-lang-link.active {
      opacity: 1;
    }
  }
}

.header-component-mobile {
  width: 100%;
  height: 5rem;

  background-color: rgba(245, 245, 247, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  z-index: 20;
  position: fixed;

  display: none;
  align-items: center;

  border-bottom: 1px solid #1c1c1e;

  padding-inline: 5%;

  @media (max-width: 960px) {
    display: flex;
  }

  .header-component-mobile-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .header-lang {
      display: flex;
      align-items: center;
      gap: 1rem;
      height: 1rem;

      .header-lang-link {
        font-size: 1rem; // 16px
        line-height: 1rem;
        text-transform: uppercase;
        color: #1d1d1f;
        opacity: 0.48;
      }

      .header-lang-link.active {
        opacity: 1;
      }
    }

    .header-menu-logo-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .header-menu-logo {
        width: 11.25rem; // 180px
        height: auto;
      }
    }
  }
}

.header-component-mobile-menu {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(245, 245, 247, 0.6);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  z-index: 30;
  position: fixed;

  display: none;
  flex-direction: column;
  gap: 1rem;

  &.open {
    display: flex;
  }

  .header-component-mobile-menu-header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 5rem;
    padding: 1.5rem;
    border-bottom: 1px solid #1c1c1e;

    .header-component-mobile-menu-logo-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .header-component-mobile-menu-logo {
        width: 2.5rem;
        height: 2.5rem;
      }
    }
  }

  .header-component-mobile-menu-element {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-inline: 2rem;

    .header-component-mobile-menu-element-links {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .header-component-mobile-menu-element-link {
        font-size: 1.438rem; // 23px
        letter-spacing: 2.3px;
        color: #1d1d1f;
        text-transform: uppercase;
        text-align: center;
        padding-block: 1.5rem;
        border-bottom: 1px solid #bcb194;

        &:last-child {
          border-bottom: 1px solid rgba(29, 29, 31, 0.3);
        }
      }
    }

    .header-component-mobile-menu-element-lower {
      display: flex;
      gap: 2rem;

      .header-component-mobile-menu-element-lower-socials {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        opacity: 0.48;

        a {
          display: flex;
          align-items: center;
          justify-content: center;

          .header-component-mobile-menu-element-lower-socials-icon {
            width: 1.5rem;
            height: auto;
          }
        }
      }

      .header-component-mobile-menu-element-lower-border {
        width: 1px;
        height: 100%;
        background-color: #1d1d1f;
      }

      .header-lang {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 1rem;
        height: 1rem;

        .header-lang-link {
          font-size: 1rem; // 16px
          line-height: 1rem;
          text-transform: uppercase;
          color: #1d1d1f;
          opacity: 0.48;
        }

        .header-lang-link.active {
          opacity: 1;
        }
      }
    }

    .header-component-mobile-menu-element-close {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: 2.5rem; // 40px

      .header-component-mobile-menu-element-close-button {
        font-size: 0.75rem;
        color: #1d1d1f;
        letter-spacing: 2.4px;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
}

.burger-component {
  width: 1.375rem;
  height: 1.063rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .burger-line {
    width: 100%;
    height: 2px;
    background-color: #1d1d1f;
    border-radius: 1px;
    transition: all 0.3s ease-in-out;
  }

  .open {
    opacity: 1;
  }

  .open:nth-child(1),
  .open:nth-child(3) {
    opacity: 0;
  }
}
