.world-map-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.188rem; // 35px
  padding-inline: 8.75rem; // 140px

  padding-top: 5rem; // 80px
  padding-bottom: 2.5rem; // 40px

  .world-map-label {
    font-size: 2.438rem; // 39px
    letter-spacing: 6.12px;
    text-transform: uppercase;
    color: #1d1d1f;
    text-align: center;
    text-wrap: balance;
  }

  .world-map-navigation {
    position: relative;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .world-map-navigation-left-scroll,
    .world-map-navigation-right-scroll {
      z-index: 1;
      position: absolute;
      top: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.75rem;
      flex-shrink: 0;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }

    .world-map-navigation-left-scroll {
      left: 0;
    }

    .world-map-navigation-right-scroll {
      right: 0;
    }

    .world-map-content-container {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      overflow-x: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch;
      cursor: grab;

      &::-webkit-scrollbar {
        display: none;
      }
      &:active {
        cursor: grabbing;
      }

      .world-map-content {
        position: relative;
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 3%;

        .world-map-content-point,
        .world-map-content-point-large {
          transition: transform 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            transform: scale(1.25);
          }
        }

        .world-map-content-point {
          position: absolute;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background-color: #c4b897;
        }

        .world-map-content-point-large {
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #c4b897;
        }

        .los-angeles-point {
          top: 54%;
          left: 26%;
        }

        .montreal-point {
          top: 35%;
          left: 73%;
        }

        .paris-point {
          top: 70%;
          left: 30%;
        }

        .london-point {
          top: 59%;
          left: 23%;
        }

        .brussels-point {
          top: 63.5%;
          left: 33.5%;
        }

        .amsterdam-point {
          top: 60.5%;
          left: 35%;
        }

        .madrid-barcelona-point {
          top: 89.5%;
          left: 13.5%;
        }

        .rome-point {
          top: 86.5%;
          left: 53%;
        }

        .helsinki-point {
          top: 30%;
          left: 75%;
        }

        .dakar-point {
          top: 35%;
          left: 5%;
        }

        .abidjan-point {
          top: 43.5%;
          left: 26%;
        }
      }
    }
  }

  .world-map-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 1.5rem; // 24px
    gap: 1.875rem; // 30px
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    .world-map-name-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      min-height: 80px;

      .world-map-name-content-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .world-map-name-content-item-wrapper {
          animation: fadeIn 0.25s ease-in-out;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.313rem; // 5px

          .world-map-name-content-item-wrapper-country {
            font-size: 1.125rem; // 18px
            letter-spacing: 1.05px;
            font-family: Campton-Bold;
            text-transform: uppercase;
            color: #c4b897;
          }

          .world-map-name-content-item-wrapper-city {
            font-size: 1rem; // 16px
            letter-spacing: 1.05px;
            font-family: Campton-Light;
            text-transform: uppercase;
            color: #1d1d1f;
          }
        }

        .world-map-name-content-item-name {
          font-size: 1.813rem; // 29px
          letter-spacing: 4.35px;
          color: #1d1d1f;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }

    .world-map-name-content:first-child {
      align-items: flex-start;
    }

    .world-map-name-content:last-child {
      align-items: flex-end;
    }
  }

  .world-map-bullets {
    display: none;
  }
}

.paris-container {
  z-index: 1;
  position: absolute;
  max-width: 26.875rem; //430px
  width: 100%;
  top: 58%;
  left: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .paris-container-title {
    font-size: 1.125rem; // 18px
    letter-spacing: 0.92px;
    font-family: Campton-Bold;
    color: #c4b897;
    text-transform: uppercase;
  }

  .paris-container-sub-title {
    font-size: 0.875rem; // 14px
    font-family: Campton-BookItalic;
    color: #1d1d1f;
  }

  .paris-container-description {
    padding-top: 1rem; // 16px
    font-size: 0.875rem; // 14px
    color: #1d1d1f;
    text-wrap: pretty;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 1640px) {
  .world-map-section {
    .world-map-label {
      font-size: 2.188rem; // 35px
      letter-spacing: 5.47px;
    }
  }
}

@media (max-width: 1500px) {
  .world-map-section {
    .world-map-label {
      font-size: 1.875rem; // 30px
      letter-spacing: 4.69px;
    }
  }
}

@media (max-width: 1320px) {
  .world-map-section {
    .world-map-label {
      font-size: 1.625rem; // 26px
      letter-spacing: 4.06px;
    }
  }
}

@media (max-width: 1180px) {
  .world-map-section {
    .world-map-label {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }
  }
}

@media (max-width: 1000px) {
  .world-map-section {
    .world-map-label {
      font-size: 1.25rem; // 20px
      letter-spacing: 1.88px;
    }
  }
}

@media (max-width: 960px) {
  .world-map-section {
    margin: 0;
    padding-inline: 5%;
    gap: 1.5rem; // 24px
    padding-top: 2.5rem; // 40px
    padding-bottom: 1.563rem; // 25px

    .world-map-label {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .world-map-navigation {
      .world-map-content-container {
        gap: 0;
        scroll-snap-type: x mandatory;

        .world-map-content {
          margin: 0;
          flex: 0 0 100%;
          scroll-snap-align: start;
        }
      }
    }

    .world-map-name-container {
      .world-map-name-content {
        display: flex;
        min-height: 80px;

        .world-map-name-content-item {
          .world-map-name-content-item-wrapper {
            transform: none;
            left: auto;
            right: auto;
            text-align: center;
          }

          .world-map-name-content-item-name {
            font-size: 1.438rem; // 23px
            letter-spacing: 2.3px;
          }
        }
      }

      .world-map-name-content:first-child,
      .world-map-name-content:last-child {
        align-items: center;
      }
    }

    .world-map-bullets {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1rem;

      .world-map-bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(29, 29, 31, 0.17);
        cursor: pointer;

        &.active {
          background-color: #1d1d1f;
        }
      }
    }
  }

  .paris-container {
    top: 60%;
    left: 40%;
    max-width: 23.75rem; // 380px
  }
}

@media (max-width: 768px) {
  .paris-container {
    top: 35%;
    left: 0;
    max-width: 100%;
  }
}
