.article-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-inline: 8.75rem;
  padding-top: 6.25rem; // 100px

  .article-content {
    padding-bottom: 2.5rem; // 40px
    border-left: 1px solid #1d1d1f;
    border-right: 1px solid #1d1d1f;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .article-content-wrapper {
      position: relative;
      width: 100%;

      .article-content-upper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.75rem; // 12px
        padding-top: 3.75rem; // 60px
        padding-inline: 10%;

        .article-pretitle {
          font-size: 1.625rem; // 26px
          font-family: Campton-Bold;
          letter-spacing: 3.8px;
          text-transform: uppercase;
          text-align: center;
          color: #bcb194;
        }

        .article-title {
          font-size: 2.938rem; // 47px
          font-family: Campton-ExtraLight;
          letter-spacing: 1.18px;
          text-transform: uppercase;
          text-align: center;
          color: #1d1d1f;
        }
      }

      .article-button-down {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1.5rem; // 24px
      }

      .article-content-lower {
        padding-top: 3.125rem; // 50px
        padding-bottom: 3.125rem; // 50px
        padding-inline: 10%;

        .article-description {
          font-size: 1.375rem; // 22px
          letter-spacing: 0.2px;
          color: #1d1d1f;
          text-align: center;
          text-wrap: pretty;
        }
      }
    }

    .article-image-container {
      width: 100%;

      .article-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        aspect-ratio: 9/4;
      }
    }
  }
}

@media (max-width: 960px) {
  .article-section {
    padding-inline: 0;
    padding-top: 5rem; // 80px
    border: none;

    .article-content {
      padding-bottom: 0;

      .article-content-wrapper {
        padding-inline: 2.75rem; // 44px

        .article-content-upper {
          background: linear-gradient(to bottom, #f7f8f9 90%, #f7f8f900 100%);
          padding-inline: 5%;
          padding-top: 1.25rem; // 20px
          padding-bottom: 1.25rem; // 20px
          border-left: 1px solid #1d1d1f;
          border-right: 1px solid #1d1d1f;
          transition: all 0.3s ease-in-out;

          &.fixed {
            position: fixed;
            top: 5rem;
            left: calc(2.75rem + 2px);
            right: calc(2.75rem + 2px);
            z-index: 10;
            border-left: none;
            border-right: none;
          }

          .article-pretitle {
            font-size: 0.875rem; // 14px
            letter-spacing: 1.57px;
          }

          .article-title {
            font-size: 1.438rem; // 23px
            letter-spacing: 2.3px;
            font-family: Campton;
          }
        }

        .article-button-down {
          display: none;
        }

        .article-content-lower {
          padding-inline: 5%;
          padding-top: 1.5rem; // 24px
          padding-bottom: 1.5rem; // 24px
          border-left: 1px solid #1d1d1f;
          border-right: 1px solid #1d1d1f;

          .article-description {
            font-size: 1.125rem; // 18px
            letter-spacing: 0.18px;
          }
        }
      }

      .article-image-container {
        padding-inline: 2.75rem; // 44px
        border-bottom: 1px solid #1d1d1f;

        .article-image {
          aspect-ratio: 16/13;
          border-left: 1px solid #1d1d1f;
          border-right: 1px solid #1d1d1f;
        }
      }

      .article-button {
        padding-inline: 2.75rem; // 44px
        width: 100%;
        display: flex;
        justify-content: center;

        a {
          width: 100%;
          display: flex;
          justify-content: center;
          border-left: 1px solid;
          border-right: 1px solid;
          border-image: linear-gradient(
              to bottom,
              #1d1d1f 50%,
              transparent 100%
            )
            1;
          border-image-slice: 0 1 0 1;
          padding-bottom: 1.5rem; // 24px
        }
      }
    }
  }
}
