.button-down-icon {
  width: 2.938rem; // 47px
  height: 2.938rem; // 47px

  @media (max-width: 1280px) {
    width: 2.25rem; // 36px
    height: 2.25rem; // 36px
  }

  @media (max-width: 960px) {
    width: 1.938rem; // 31px
    height: 1.938rem; // 31px
  }
}
