.our-channel-activity-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8.75rem 0 8.75rem;
  padding-top: 5rem; // 80px
  padding-bottom: 5rem; // 80px
  gap: 4.875rem; // 78px

  .our-channel-activity-title {
    text-align: center;
    text-wrap: balance;
    font-size: 3.188rem; // 51px
    letter-spacing: 5.1px;
    text-transform: uppercase;
    color: #1d1d1f;
  }

  .our-channel-activity-country-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7.75rem; // 124px
  }

  .our-channel-activity-country {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem; // 20px

    .our-channel-activity-country-title {
      position: absolute;
      top: -1.25rem; // -20px
      font-size: 2.5rem; // 40px
      letter-spacing: 6px;
      text-transform: uppercase;
      text-align: center;
      color: #bcb194;
    }

    .our-channel-activity-carousel {
      display: flex;
      width: 100%;
      margin-top: 3.125rem; // 50px
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scrollbar-width: none;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;
      cursor: grab;

      &::-webkit-scrollbar {
        display: none;
      }

      &:active {
        cursor: grabbing;
      }

      transition: transform 0.3s ease;

      .our-channel-activity-carousel-slide {
        scroll-snap-align: start;
        flex: 0 0 100%;
        padding-inline: 3rem; // 48px

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        .our-channel-activity-carousel-slide-logo-grid {
          display: grid;
          gap: 1.875rem; // 30px

          .our-channel-activity-carousel-slide-logo-item {
            .our-channel-activity-carousel-slide-logo-item-img {
              width: 100%;
              height: auto;
              object-fit: contain;
            }
          }
        }
      }
    }

    .our-channel-activity-bullets {
      margin: 0.625rem 0; // 10px 0
      display: flex;
      justify-content: center;
      gap: 0.5rem; // 8px

      .our-channel-activity-bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(29, 29, 31, 0.17);
        cursor: pointer;

        &.active {
          background-color: #1d1d1f;
        }
      }
    }

    .our-channel-activity-border {
      position: absolute;
      width: 17px;
      height: 17px;

      &-top-left {
        top: 0;
        left: 0;
        border-top: 1px solid #707070;
        border-left: 1px solid #707070;
      }

      &-top-right {
        top: 0;
        right: 0;
        border-top: 1px solid #707070;
        border-right: 1px solid #707070;
      }

      &-bottom-left {
        bottom: 0;
        left: 0;
        border-bottom: 1px solid #707070;
        border-left: 1px solid #707070;
      }

      &-bottom-right {
        bottom: 0;
        right: 0;
        border-bottom: 1px solid #707070;
        border-right: 1px solid #707070;
      }
    }

    .our-channel-activity-carousel-left-scroll,
    .our-channel-activity-carousel-right-scroll {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      height: 100%;
      transform: translateY(-50%);
      width: 2.75rem;
      flex-shrink: 0;
      cursor: pointer;
      background-color: #f5f5f7;
      z-index: 10;
      -webkit-tap-highlight-color: transparent;
    }

    .our-channel-activity-carousel-left-scroll {
      left: -3.125rem; // -50px
    }

    .our-channel-activity-carousel-right-scroll {
      right: -3.125rem; // -50px
    }
  }
}

@media (max-width: 960px) {
  .our-channel-activity-section {
    margin: 0;
    padding-top: 2.5rem; // 40px
    padding-bottom: 2.5rem; // 40px
    gap: 2.5rem; // 40px

    .our-channel-activity-title {
      font-size: 1.875rem; // 30px
      letter-spacing: 3px;
      padding-inline: 3.125rem; // 50px
    }

    .our-channel-activity-country-container {
      gap: 3.75rem; // 60px

      .our-channel-activity-country {
        gap: 0.625rem; // 10px
        padding-inline: 3.125rem; // 50px

        .our-channel-activity-border {
          display: none;
        }

        .our-channel-activity-country-title {
          top: -0.625rem; // -10px
          font-size: 1.5rem; // 24px
          letter-spacing: 4.5px;
        }

        .our-channel-activity-carousel {
          .our-channel-activity-carousel-slide {
            padding-inline: 0;
          }
        }

        .our-channel-activity-carousel-left-scroll {
          left: 0;
        }

        .our-channel-activity-carousel-right-scroll {
          right: 0;
        }
      }
    }
  }
}
