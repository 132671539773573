.picture-section {
  padding-inline: 8.75rem;
  padding-top: 6.25rem; // 100px
  padding-bottom: 2.5rem; // 40px

  .picture-container {
    position: relative;
    width: 100%;
    height: 100%;

    .picture-content-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 9/4;
    }

    .picture-text {
      font-size: 1rem; // 16px
      letter-spacing: 2.25px;
      color: #f6f7f8;
      text-align: center;
      text-transform: uppercase;
    }

    .picture-text-left {
      position: absolute;
      bottom: 3%;
      left: 3%;
    }

    .picture-text-right {
      position: absolute;
      bottom: 3%;
      right: 3%;
    }
  }
}

@media (max-width: 960px) {
  .picture-section {
    padding-inline: 0;
    padding-top: 0;
    padding-bottom: 0;

    .picture-container {
      .picture-content-image {
        aspect-ratio: 9/15;
        max-height: 80svh;
      }

      .picture-background-gradient {
        position: absolute;
        left: 0;
        width: 100%;

        &.top {
          height: 30%;
          top: 0;
          background: linear-gradient(to bottom, #1d1d1f, #1d1d1f00);
        }

        &.bottom {
          height: 30%;
          bottom: 0;
          background: linear-gradient(to top, #1d1d1f, #1d1d1f00);
        }
      }

      .picture-text-left {
        top: 6.25rem; // 100px
        bottom: unset;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }

      .picture-text-right {
        top: 8.75rem; // 140px
        bottom: unset;
        right: 50%;
        transform: translateX(50%);
        z-index: 1;
      }

      .picture-text-left.picture-text {
        font-size: 1.438rem; // 23px
        letter-spacing: 2.3px;
        width: 100%;
        padding-inline: 5%;
      }

      .picture-text-right.picture-text {
        font-size: 1.125rem; // 18px
        letter-spacing: 1.95px;
        width: 100%;
        padding-inline: 5%;
      }
    }
  }
}
