.latest-news-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem; // 32px
  gap: 0.5rem; // 8px

  .pagination-button {
    font-size: 1.188rem; // 19px
    font-family: Campton-Light;
    cursor: pointer;
    color: #1d1d1f;

    &.active {
      text-decoration: underline;
    }
  }
}
