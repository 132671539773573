.sound-component {
  cursor: pointer;

  .sound-icon {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 960px) {
  .sound-component {
    .sound-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
