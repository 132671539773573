.digital-activity-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #efeade;
  padding-inline: 8.75rem; // 140px
  padding-top: 7.5rem; // 120px
  padding-bottom: 7.5rem; // 120px
  gap: 4.875rem; // 78px

  .digital-activity-title {
    text-align: center;
    text-wrap: balance;
    font-size: 3.188rem; // 51px
    letter-spacing: 5.1px;
    text-transform: uppercase;
    color: #1d1d1f;
  }

  .digital-activity-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10%;

    .digital-activity-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      .digital-activity-content {
        font-size: 1.25rem; // 20px
        letter-spacing: 0.2px;
        text-align: center;
        color: #1d1d1f;
        text-wrap: pretty;
        margin-bottom: 1.875rem; // 30px
        max-width: 43.75rem; // 700px
        width: 100%;
      }

      .digital-activity-button {
        font-size: 1.125rem; // 18px
        letter-spacing: 2.7px;
        text-align: center;
        color: #bcb194;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }

    .digital-activity-image-container {
      flex: 1;
    }
  }
}

@media (max-width: 960px) {
  .digital-activity-section {
    padding-inline: 5%;
    padding-top: 3.75rem; // 60px
    padding-bottom: 3.75rem; // 60px
    gap: 3.75rem; // 60px

    .digital-activity-title {
      font-size: 1.875rem; // 30px
      letter-spacing: 3px;
    }

    .digital-activity-wrapper {
      flex-direction: column;
      gap: 3.75rem; // 60px

      .digital-activity-content-container {
        .digital-activity-content {
          font-size: 1.125rem; // 18px
          letter-spacing: 0.18px;
        }
      }
    }
  }
}
