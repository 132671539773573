.legal-mention-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.125rem; // 50px

  margin: 0 8.75rem 0 8.75rem;
  padding-top: 11.688rem; // 187px
  padding-bottom: 5rem; //80px

  .legal-mention-title {
    text-align: center;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #1d1d1f;
  }

  .legal-mention-content {
    text-align: center;
    font-size: 1.375rem; // 22px
    letter-spacing: 0.2px;
    color: #1d1d1f;

    h2 {
      padding-bottom: 5rem; // 80px
      margin-bottom: 4.125rem; // 66px
      border-bottom: 1px #1d1d1f solid;
      font-size: 2.188rem; // 35px
      font-weight: 400;
    }
  }
}

@media (max-width: 960px) {
  .legal-mention-section {
    margin: 0;
    padding-inline: 5%;
    gap: 1.375rem; // 22px
    padding-top: 8.125rem; // 130px
    padding-bottom: 2.5rem; // 40px

    .legal-mention-title {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .legal-mention-content {
      font-size: 1.125rem; // 18px
      letter-spacing: 0.18px;

      h2 {
        padding-bottom: 1.5rem; // 24px
        margin-bottom: 1rem; // 16px
        font-size: 1.125rem; // 18px
      }
    }
  }
}
