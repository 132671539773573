.carousel-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.438rem; // 55px

  padding-top: 5rem; // 80px
  padding-bottom: 5rem; // 80px

  .carousel-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-transform: uppercase;
    text-align: center;
    color: #1d1d1f;
  }

  .carousel-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .carousel-content-row {
      position: relative;
      overflow: hidden;
      white-space: nowrap;

      .carousel-content-inner {
        display: inline-block;

        .carousel-content-image-wrapper {
          display: inline-block;
          width: 8.75rem; // 140px
          height: 6.25rem; // 100px
          margin-right: 3.125rem; // 50px

          .carousel-content-image {
            width: auto;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 960px) {
  .carousel-section {
    margin: 0;
    padding-top: 2.5rem; // 40px
    padding-bottom: 2.5rem; // 40px
    gap: 1.5rem; // 24px

    .carousel-title {
      padding-inline: 5%;
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .carousel-content {
      .carousel-mobile-wrapper {
        position: relative;
        width: 100%;

        .carousel-mobile-container {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          overflow-x: auto;
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
          scrollbar-width: none;
          -ms-overflow-style: none;
          -webkit-overflow-scrolling: touch;
          cursor: grab;

          &::-webkit-scrollbar {
            display: none;
          }

          &:active {
            cursor: grabbing;
          }

          transition: transform 0.3s ease;

          .carousel-mobile-group {
            flex: 0 0 100%;
            scroll-snap-align: start;
            display: flex;
            flex-direction: column;
            padding-inline: 3rem; // 48px
            gap: 1.5rem; // 24px
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

            .carousel-mobile-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 1.5rem; // 24px

              .carousel-mobile-image {
                width: calc((100% - 6rem) / 5);
                height: auto;
                object-fit: contain;
              }
            }
          }
        }

        .carousel-mobile-scroll-left,
        .carousel-mobile-scroll-right {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          width: 2.75rem;
          cursor: pointer;
          background-color: #f5f5f7;
          height: 100%;
          z-index: 10;
          -webkit-tap-highlight-color: transparent;
        }

        .carousel-mobile-scroll-left {
          left: 0;
        }

        .carousel-mobile-scroll-right {
          right: 0;
        }

        .carousel-bullets {
          margin-top: 1.375rem; // 22px
          display: flex;
          justify-content: center;
          gap: 0.5rem; // 8px

          .carousel-bullet {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(29, 29, 31, 0.17);
            cursor: pointer;

            &.active {
              background-color: #1d1d1f;
            }
          }
        }
      }
    }
  }
}
