.template-links-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 8.75rem;
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .template-links-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1.5rem; // 24px

    .template-links-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem; // 16px
      margin: 0 auto;

      .template-links-item-label {
        font-size: 1.25rem; // 20px
        letter-spacing: 3px;
        font-family: Campton-Bold;
        text-transform: uppercase;
        color: #bcb194;
      }
    }
  }
}

@media (max-width: 960px) {
  .template-links-section {
    margin: 0;
    padding-inline: 5%;
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px
    gap: 0.625rem; // 10px

    .template-links-content {
      .template-links-item {
        .template-links-item-label {
          font-size: 1.125rem; // 18px
          letter-spacing: 1.95px;
          font-family: Campton;
          color: #1d1d1d;
        }
      }
    }
  }
}
