.button-active-component-container {
  background: none;
  border: none;
  padding: 0;

  .button-active-component {
    border: 2px solid #c0b59a;
    border-radius: 50%;
    width: 2.938rem;
    height: 2.938rem;
    position: relative;
    transition: transform 0.3s ease;

    &.dark {
      border: 2px solid #1d1d1f;
    }

    &:hover {
      cursor: pointer;

      &.hover-dark {
        border: 2px solid #1d1d1f;

        .button-active-vertical,
        .button-active-horizontal {
          border-color: #1d1d1f;
        }
      }
    }

    @media (max-width: 960px) {
      width: 1.625rem;
      height: 1.625rem;
      border-width: 1px;

      &.dark {
        border-width: 1px;
      }
    }

    .button-active-vertical,
    .button-active-horizontal {
      position: absolute;
      top: 50%;
      left: 50%;
      background: #c0b59a;
      transition: all 0.3s ease;

      &.dark {
        background: #1d1d1f;
      }
    }

    .button-active-vertical {
      transform: translate(-50%, -50%);
      width: 2px;
      height: 1.196rem;

      &.active {
        width: 0;
      }

      @media (max-width: 960px) {
        width: 1px;
        height: 0.75rem;
      }
    }

    .button-active-horizontal {
      transform: translate(-50%, -50%);
      height: 2px;
      width: 1.182rem;

      @media (max-width: 960px) {
        height: 1px;
        width: 0.75rem;
      }
    }
  }
}
