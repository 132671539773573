.button-plus-component-container {
  .button-plus-component {
    border: 2px solid #c0b59a;
    border-radius: 50%;

    width: 2.938rem; // 47px
    height: 2.938rem; // 47px

    position: relative;

    &.dark {
      border: 2px solid #1d1d1f;
    }

    &:hover {
      cursor: pointer;
      border: 2px solid #c0b59a;
      transform: rotate(-45deg);

      .button-plus-vertical,
      .button-plus-horizontal {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &.hover-dark {
        border: 2px solid #1d1d1f;

        .button-plus-vertical {
          border-top: 2px solid #1d1d1f;
        }

        .button-plus-horizontal {
          border-left: 2px solid #1d1d1f;
        }
      }

      &.dark {
        border: 2px solid #1d1d1f;

        .button-plus-vertical {
          border-top: 2px solid #1d1d1f;
        }

        .button-plus-horizontal {
          border-left: 2px solid #1d1d1f;
        }
      }
    }

    @media (max-width: 1280px) {
      width: 2.25rem; // 36px
      height: 2.25rem; // 36px
    }

    @media (max-width: 960px) {
      width: 1.625rem; // 26px
      height: 1.625rem; // 26px

      border: 1px solid #c0b59a;

      &.dark {
        border: 1px solid #1d1d1f;
      }

      &:hover {
        border: 1px solid #c0b59a;

        &.hover-dark {
          border: 1px solid #1d1d1f;

          .button-plus-vertical {
            border-top: 1px solid #1d1d1f;
          }

          .button-plus-horizontal {
            border-left: 1px solid #1d1d1f;
          }
        }

        &.dark {
          border: 1px solid #1d1d1f;

          .button-plus-vertical {
            border-top: 1px solid #1d1d1f;
          }

          .button-plus-horizontal {
            border-left: 1px solid #1d1d1f;
          }
        }
      }
    }

    .button-plus-vertical {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.182rem; // 18.91px
      border-top: 2px solid #c0b59a;
      transition: transform 0.3s ease;

      &.dark {
        border-top: 2px solid #1d1d1f;
      }

      @media (max-width: 1280px) {
        width: 0.938rem; // 14.99px
      }

      @media (max-width: 960px) {
        width: 0.75rem; // 12px
        border-top: 1px solid #c0b59a;

        &.dark {
          border-top: 1px solid #1d1d1f;
        }
      }
    }

    .button-plus-horizontal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1.196rem; // 19.14px
      border-left: 2px solid #c0b59a;
      transition: transform 0.3s ease;

      &.dark {
        border-left: 2px solid #1d1d1f;
      }

      @media (max-width: 1280px) {
        height: 0.938rem; // 14.99px
      }

      @media (max-width: 960px) {
        height: 0.75rem; // 12px
        border-left: 1px solid #c0b59a;

        &.dark {
          border-left: 1px solid #1d1d1f;
        }
      }
    }
  }
}
