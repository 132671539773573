// Campton

@font-face {
  font-family: "Campton-ExtraLight";
  src: url("../fonts/campton/Campton-ExtraLight.otf");
  font-weight: 200;
}

@font-face {
  font-family: "Campton-Light";
  src: url("../fonts/campton/Campton-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Campton";
  src: url("../fonts/campton/Campton-Book.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Campton-Medium";
  src: url("../fonts/campton/Campton-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Campton-Bold";
  src: url("../fonts/campton/Campton-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Campton-ExtraBold";
  src: url("../fonts/campton/Campton-ExtraBold.otf");
  font-weight: 900;
}

// Campton Italic

@font-face {
  font-family: "Campton-BookItalic";
  src: url("../fonts/campton/Campton-BookItalic.otf");
  font-weight: 400;
}

// SangBleu

@font-face {
  font-family: "SangBleu-Light";
  src: url("../fonts/sangbleu/SangBleuKingdom-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "SangBleu";
  src: url("../fonts/sangbleu/SangBleuKingdom-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "SangBleu-Bold";
  src: url("../fonts/sangbleu/SangBleuKingdom-Bold.otf");
  font-weight: 700;
}
