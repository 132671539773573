.frieze-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-inline: 8.75rem; // 140px
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .frieze-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    color: #1d1d1f;
    text-align: center;
    text-transform: uppercase;

    margin-bottom: 2.5rem; // 40px
  }

  .frieze-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem; // 32px

    .frieze-value {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem; // 16px

      .frieze-value-label {
        font-size: 2.75rem; // 44px
        color: #c4b897;
        text-transform: uppercase;
        text-align: center;
      }

      .frieze-value-description {
        max-width: 42rem; // 672px
        font-size: 1.25rem; // 20px
        letter-spacing: 0.2px;
        color: #1d1d1f;
        text-align: center;
        text-wrap: pretty;
      }

      .frieze-value-line {
        display: block;
        width: 1px;
        height: 6.25rem; // 100px
        background-color: #1d1d1f;
      }

      &:last-child {
        .frieze-value-line {
          display: none;
        }
      }
    }
  }
}

.frieze-section {
  @media (max-width: 960px) {
    // padding-top: 1.563rem; // 25px
    // padding-bottom: 1.563rem; // 25px
    padding-inline: 5%;
    border-bottom: 1px solid #1d1d1f;

    .frieze-title {
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
      margin-bottom: 1.5rem; // 24px
    }

    .frieze-values {
      gap: 1rem; // 16px

      .frieze-value {
        gap: 0.3125rem; // 5px

        .frieze-value-label {
          font-size: 1.438rem; // 23px
          letter-spacing: 2.3px;
        }

        .frieze-value-description {
          font-size: 1.125rem; // 18px
          letter-spacing: 0.18px;
        }

        .frieze-value-line {
          width: 1px;
          height: 3.125rem; // 50px
        }
      }
    }
  }
}
