.page-list-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.438rem; // 55px
  margin: 0 auto;

  padding-top: 11.063rem; // 177px
  padding-bottom: 2.5rem; // 40px

  &.home {
    padding-top: 2.5rem; // 40px
  }

  @media (max-width: 960px) {
    gap: 2.5rem; // 40px
    padding-top: 8.063rem; // 129px

    &.home {
      padding-inline: 5%;
      padding-top: 1.563rem; // 25px
      padding-bottom: 1.563rem; // 25px
    }
  }

  .page-list-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 960px) {
      gap: 0.5rem; // 8px

      .page-list-bullets {
        margin: 1.5rem 0; // 24px
        display: flex;
        justify-content: center;
        gap: 0.5rem; // 8px

        .page-list-bullet {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: rgba(29, 29, 31, 0.17);
          cursor: pointer;

          &.active {
            background-color: #1d1d1f;
          }
        }
      }
    }

    .page-list-title {
      text-align: center;
      text-wrap: balance;
      font-size: 2.5rem; // 40px
      letter-spacing: 6px;
      text-transform: uppercase;
      color: #1d1d1f;
      max-width: 43.75rem; // 700px

      &.home {
        font-family: Campton-ExtraLight;
        font-size: 4rem; // 64px
        letter-spacing: 1.75px;
        max-width: 100%;
      }

      @media (max-width: 960px) {
        padding-inline: 5%;
        font-size: 1.688rem; // 27px
        letter-spacing: 3.45px;

        &.home {
          font-size: 1.688rem; // 27px
          letter-spacing: 2.3px;
          font-family: Campton;
          max-width: 25rem; // 400px
        }
      }
    }

    .page-list-description {
      text-align: center;
      text-wrap: balance;
      font-size: 1.375rem; // 22px
      letter-spacing: 0.2px;
      color: #1d1d1f;
      max-width: 43.75rem; // 700px
      margin-bottom: 1.5rem; // 24px

      @media (max-width: 960px) {
        font-size: 1.125rem; // 18px
        letter-spacing: 0.18px;
        padding-inline: 5%;
        margin-bottom: 1rem; // 16px
      }
    }

    .page-list-content-activities {
      width: 100%;
      padding-top: 1.5rem; // 24px

      .page-list-content-activities-container {
        display: flex;
        width: 100%;

        @media (max-width: 960px) {
          overflow-x: auto;
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
          scrollbar-width: none;
          -ms-overflow-style: none;
          -webkit-overflow-scrolling: touch;
          cursor: grab;

          &::-webkit-scrollbar {
            display: none;
          }

          &:active {
            cursor: grabbing;
          }

          transition: transform 0.3s ease;
        }

        .page-list-content-activities-item {
          position: relative;
          display: flex;
          flex: 0 0 20%;

          @media (max-width: 960px) {
            border-top: 1px solid #1d1d1f;
            border-bottom: 1px solid #1d1d1f;
            padding-inline: 2.75rem;
            flex: 0 0 100%;
            scroll-snap-align: start;
          }

          .page-list-content-activities-card {
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 1.25rem; // 20px

            @media (max-width: 960px) {
              width: 100%;
              height: auto;
              aspect-ratio: 9/13;
              max-height: 80svh;
              gap: 0;
            }

            .page-list-content-activities-card-shadow {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 30%;
              background: linear-gradient(to top, #1d1d1f, transparent);
            }

            .page-list-content-activities-card-image {
              border: 1px solid #fbfbfb;
              width: 100%;
              height: 100%;
              aspect-ratio: 9/13;
              object-fit: cover;

              @media (max-width: 960px) {
                border: none;
                border-left: 1px solid #1d1d1f;
                border-right: 1px solid #1d1d1f;
              }
            }

            .page-list-content-activities-card-title {
              position: absolute;
              bottom: 3%;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
              min-height: 3.75rem; // 60px
              font-size: 1.625rem; // 26px
              letter-spacing: 3.8px;
              padding-inline: 0.625rem; // 10px
              color: #f7f8f9;
              text-transform: uppercase;
              word-break: break-word;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              z-index: 1;

              @media (max-width: 960px) {
                min-height: auto;
                font-size: 1.188rem; // 19px
                letter-spacing: 2.4px;
              }
            }
          }

          .page-list-content-scroll-arrow {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 2.75rem;
            justify-content: center;
            align-items: center;
            z-index: 1;

            @media (max-width: 960px) {
              display: flex;
            }

            &.left {
              left: 0;
            }

            &.right {
              right: 0;
            }
          }
        }
      }
    }
  }
}
