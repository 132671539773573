.life-exp-activity-section {
  position: relative;
  padding-inline: 8.75rem; // 140px
  padding-top: 7.5rem; // 120px
  padding-bottom: 4.375rem; // 70px
  height: calc(100svh - 6.25rem);

  .life-exp-activity-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 100%;

    .life-exp-activity-title {
      text-align: center;
      text-wrap: balance;
      font-size: 3.188rem; // 51px
      letter-spacing: 5.1px;
      text-transform: uppercase;
      color: #1d1d1f;

      &.light {
        color: #f7f8f9;
      }
    }

    .life-exp-activity-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .life-exp-activity-content {
        font-size: 1.25rem; // 20px
        letter-spacing: 0.2px;
        text-align: center;
        color: #1d1d1f;
        text-wrap: pretty;
        margin-bottom: 1.875rem; // 30px
        width: 100%;

        &.light {
          color: #f7f8f9;
        }
      }

      .life-exp-activity-button {
        font-size: 1.125rem; // 18px
        letter-spacing: 2.7px;
        text-align: center;
        color: #bcb194;
        text-decoration: underline;
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 960px) {
  .life-exp-activity-section {
    padding-inline: 5%;
    padding-top: 3.75rem; // 60px
    padding-bottom: 3.75rem; // 60px
    height: calc(100svh - 5rem);

    .life-exp-activity-content-wrapper {
      .life-exp-activity-title {
        font-size: 1.875rem; // 30px
        letter-spacing: 3px;
      }

      .life-exp-activity-wrapper {
        .life-exp-activity-content {
          font-size: 1.125rem; // 18px
          letter-spacing: 0.18px;
        }
      }
    }
  }
}
