.data-table-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.438rem; // 55px

  padding-inline: 8.75rem; // 140px
  padding-top: 2.5rem; // 40px
  padding-bottom: 2.5rem; // 40px

  .data-table-title {
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    color: #1d1d1f;
    text-align: center;
    text-transform: uppercase;
  }

  .data-table-content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
  }

  .data-table-bullets {
    display: none;
  }
}

@media (max-width: 960px) {
  .data-table-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;

    padding-inline: 0;
    padding-top: 0;
    padding-bottom: 1.563rem; // 25px

    .data-table-title {
      display: none; // for now
      font-size: 1.438rem; // 23px
      letter-spacing: 3.45px;
    }

    .data-table-content-mobile {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #1d1d1f;

      .data-table-content-mobile-wrapper {
        display: flex;
        width: 100%;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        cursor: grab;

        &::-webkit-scrollbar {
          display: none;
        }

        &:active {
          cursor: grabbing;
        }

        transition: transform 0.3s ease;

        .data-table-content-mobile-container {
          flex: 0 0 100%;
          scroll-snap-align: start;
          position: relative;
          display: flex;
          padding-inline: 2.75rem; // 44px

          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;

          .data-table-content-mobile-item-wrapper {
            width: 100%;

            .data-table-content-mobile-item {
              border-bottom: 1px solid #1d1d1f;

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .data-table-content-mobile-container-left-scroll,
      .data-table-content-mobile-container-right-scroll {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        width: 2.75rem;
        flex-shrink: 0;
        cursor: pointer;
        background-color: #f5f5f7;
        z-index: 10;
        -webkit-tap-highlight-color: transparent;
      }

      .data-table-content-mobile-container-left-scroll {
        left: 0;
      }

      .data-table-content-mobile-container-right-scroll {
        right: 0;
      }
    }

    .data-table-bullets {
      display: flex;
      justify-content: center;
      gap: 0.5rem; // 8px

      .data-table-bullet {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgba(29, 29, 31, 0.17);
        cursor: pointer;

        &.active {
          background-color: #1d1d1f;
        }
      }
    }
  }
}
