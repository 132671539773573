.footer-component {
  width: 100%;

  background-color: #f5f5f7;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-inline: 8.75rem; // 140px
  padding-bottom: 2.5rem; // 40px
  padding-top: 2.5rem; // 40px

  border-top: 1px solid #1d1d1f;

  .footer-left,
  .footer-right {
    flex: 1;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem; // 20px

    .footer-left-logo {
      width: 8.75rem; // 140px
      height: auto;
    }

    .footer-left-copyright {
      display: flex;
      align-items: center;

      .footer-left-copyright-text {
        font-size: 1rem; // 16px
        color: #1d1d1f;
      }
    }
  }

  .footer-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem; // 24px

    .footer-center-icon {
      height: auto;
      width: 1.875rem; // 30px
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .footer-right-list {
      list-style-type: none;

      padding: 0;
      margin: 0;

      text-align: right;
      letter-spacing: 0.14px;

      .footer-right-list-item-link {
        font-size: 1rem; // 16px
        color: #1d1d1f;
      }
    }
  }

  .footer-copyright {
    display: none;
  }
}

@media (max-width: 960px) {
  .footer-component {
    flex-direction: column;
    align-items: center;
    padding-inline: 5%;
    padding-top: 1.563rem; // 25px
    padding-bottom: 1.563rem; // 25px
    gap: 1rem;

    &.not-news-page {
      margin-top: 2.5rem;
    }

    .footer-left,
    .footer-right,
    .footer-center {
      flex: none;
      width: 100%;
      align-items: center;
      text-align: center;
    }

    .footer-left {
      order: 1;
      gap: 0;

      .footer-left-copyright {
        display: none;
      }
    }

    .footer-center {
      order: 3;
      justify-content: center;

      .footer-center-icon {
        width: 1.5rem; // 24px
        height: auto;
      }
    }

    .footer-right {
      order: 2;
      align-items: center;
      gap: 0;

      .footer-right-list {
        text-align: center;

        .footer-right-list-item-link {
          font-size: 0.875rem; // 14px
          font-family: Campton-ExtraLight;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }
    }

    .footer-copyright {
      display: flex;
      font-size: 0.75rem; // 12px
      color: #1d1d1f;
      order: 5;
    }
  }
}
