.investors-section {
  display: flex;
  flex-direction: column;
  color: #1d1d1f;

  margin: 0 8.75rem 0 8.75rem;
  padding-top: 6.25rem; // 100px
  padding-bottom: 2.5rem; // 40px
  border-left: 1px solid #1d1d1f;
  border-right: 1px solid #1d1d1f;

  .investors-label {
    margin: 4.813rem 0; // 77px
    font-size: 2.5rem; // 40px
    letter-spacing: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #1d1d1f;
  }

  .investors-menu-item {
    border-bottom: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 5%;
    padding-top: 1%;
    padding-bottom: 1%;

    .investors-menu-item-header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .investors-menu-item-title {
        font-size: 4.375rem; // 70px
        font-family: SangBleu-Bold;
      }
    }
  }

  .investors-spawn {
    display: none;
  }

  .investors-spawn.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-top: 2.5rem; // 40px
    padding-bottom: 2.5rem; // 40px
    gap: 1.5rem; // 24px

    .investors-spawn-element {
      border-bottom: 1px black solid;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2.5rem; // 40px
      width: 80%;
      padding-right: 5%;
      padding-bottom: 0.625rem; // 10px

      .investors-spawn-element-date {
        color: #cbb478;
        font-size: 1.25rem; // 20px
        letter-spacing: 0.2px;
        flex-shrink: 0;
        width: 20%;
      }

      .investors-spawn-element-title {
        width: 70%;
      }

      .investors-spawn-element-icons {
        width: 10%;
        display: flex;
        justify-content: end;
        gap: 1rem;

        .material-symbols-outlined {
          border: 1px black solid;
          padding: 3px;
          font-size: medium;
          cursor: pointer;
          font-weight: bold;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .investors-section {
    margin: 0;
    padding-inline: 5%;
    border: none;
    padding-top: 6.5rem; // 104px
    padding-bottom: 1.563rem; // 25px

    .investors-label {
      margin: 0 0 1.5rem 0; // 24px
      font-size: 1.438rem; // 23px
      letter-spacing: 2.3px;
    }

    .investors-menu-item {
      padding-inline: 0;
      padding-top: 2%;
      padding-bottom: 2%;

      .investors-menu-item-header {
        .investors-menu-item-title {
          font-size: 1.438rem; // 23px
        }
      }
    }

    .investors-spawn.active {
      padding-top: 1.563rem; // 25px
      padding-bottom: 1.563rem; // 25px

      .investors-spawn-element {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0.625rem; // 10px
        padding-right: 0;

        .investors-spawn-element-date {
          width: 100%;
          text-align: center;
          font-size: 1.188rem; // 19px
        }

        .investors-spawn-element-title {
          width: 100%;
          text-align: center;
          font-size: 1.125rem; // 18px
        }

        .investors-spawn-element-icons {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
